import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"
import ImageGallery from "react-image-gallery"
import { Link } from "gatsby"
import Box from "@material-ui/core/Box"
import ModernIcon from "@material-ui/icons/TrendingUp"
import EasyInstallIcon from "@material-ui/icons/Check"
import KeylessIcon from "@material-ui/icons/LockOpen"
import UniversalIcon from "@material-ui/icons/FlipCameraAndroid"
import Hide from "../components/hide"
import { IMAGE_GALLERY } from "../stores/cart-store"

const PageLayout = styled.div`
  font-family: Helvetica;
  width: 100%;
  height: auto;
  position: relative;

  video {
    top: 80px;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: -2;
  }

  #hero-img {
    top: 80px;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: -2;
  }

  .alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: "10px",
  },
  icons: {
    cursor: "pointer",
    fontSize: "42px",
    fill: "#afafaf",
  },
  formControl: {
    width: "100%",
  },
}))

const CTAButton = styled.button`
  padding: 0 25px;
  border-radius: 60px;
  color: white;
  font: 600 18px/42px "Poppins", sans-serif;
  background-color: #0084ff;
  border: none;
  cursor: pointer;

  @media only screen and (min-width: 800px) {
    padding: 0 85px;
    border-radius: 60px;
    color: white;
    font: 600 27px/72px "Poppins", sans-serif;
    background-color: #0084ff;
    border: none;
  }
`

const HeroContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin-top: 80px;
  width: 100%;
  z-index: 1;
  padding: 50px;
  img {
    width: 50%;
    max-width: 550px;
    margin-bottom: 24px;
  }

  h2 {
    color: white;
    font-size: 14px;
    font-weight: 500;
  }

  @media only screen and (min-width: 800px) {
    padding: 150px;
    h2 {
      color: white;
      font-size: 19px;
      font-weight: 500;
    }
  }
`
const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  z-index: -1;
`

const ContentOverlay = styled.div`
  grid-column: 1 / span 4;
  grid-row: 1 / span 4;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;
`

const ImgContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  background: #f7f7f7;
`

const SectionWrapper = styled.div`
  background-color: ${props => (props.color ? props.color : "#fff")};

  h2 {
    text-align: ${props => (props.leftAlign ? "left" : "center")};
    text-transform: uppercase;
    font: 600 30px/29px "Poppins", sans-serif;
    margin: 0 0 25px;
    color: #374048;
  }
  p {
    text-align: ${props => (props.leftAlign ? "left" : "center")};
    max-width: 710px;
    margin: ${props => (props.leftAlign ? "0" : "0 auto 20px")};
    font: 300 16px/24px "Poppins", sans-serif;
    color: #969da3;
  }

  @media only screen and (min-width: 767px) {
    h2 {
      font: 600 32px/29px "Poppins", sans-serif;
    }
    p {
      font: 300 20px/32px "Poppins", sans-serif;
    }
  }
`

const QuoteContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 32px;
  padding-bottom: 100px;

  @media only screen and (min-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
`

const Quote = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
  }
`

const FAQContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Faq = styled.div`
  text-align: center;
  margin-bottom: 40px;
  h3 {
    color: #374048;
    font: 600 20px/1 "Poppins", sans-serif;
    margin: 0 0 8px;
  }

  p {
    font: 400 16px/25px "Poppins", sans-serif;
    margin: 0;
  }
`

const Hr = styled.hr`
  width: 82px;
  height: 6px;
  background: #eee;
  margin: ${props => (props.leftAlign ? "32px 0" : "0 auto 32px")};
`

const SiteWrapper = styled.div`
  width: 100%;
  background-color: white;
`
const ContentWrapper = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  padding: 62px 16px 62px 16px;
`

const CTAStrip = styled.div`
  width: 100%;
  background-color: #11334f;
  text-align: center;
  color: white;
  h2 {
    max-width: 700px;
    margin: 0 auto;
    color: white;
    line-height: 50px;
    font-size: 32px;
  }
`

const MainFeatures = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  grid-gap: 16px;

  @media only screen and (min-width: 450px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }
`
const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin: 32px 0 17.5px;
    font-family: Din, Helvetica, sans-serif;
    font-weight: 700;
    color: #0084ff;
    font-size: 18px;
    letter-spacing: 0;
    text-align: left;
    text-transform: none;
  }

  p {
    font-family: Din, Helvetica, sans-serif;
    font-weight: 400;
    color: #4a4a4a;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
  }
`

const LeftAlignedFeature = styled(FeatureCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h2 {
    text-align: left;
    margin: 0 16px 0 0;
  }
  p {
    text-align: left;
  }
`

const FeatureLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media only screen and (min-width: 450px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const LockLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 52px;
  margin-top: 30px;

  @media only screen and (min-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
`

const isMobile = () => {
  if (typeof window === "undefined") {
    return false
  }

  return window.innerWidth < 767
}

const IndexPage = ({ cart: cartStore }) => {
  const classes = useStyles()

  return (
    <Layout hideSubNav fullWidth homepage>
      <SEO title="Home | Locking hand hole cover" />
      <PageLayout>
        <BackDrop />
        {isMobile() ? (
          <img id="hero-img" src="front-side.jpg" />
        ) : (
          <video src="new_lock.mp4" autoplay="true" loop muted></video>
        )}
        <HeroContainer>
          <ContentOverlay>
            <img alt="light-pole-defender-logo" src="logo-writing.png" />

            <Hide xs>
              <h2>
                Protect yourself from being a victim of
                <strong style={{ color: "#0084ff" }}> wire theft.</strong>
              </h2>
            </Hide>
            <ScrollLink
              activeClass="active"
              to="begining"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <CTAButton>Learn More</CTAButton>
            </ScrollLink>
          </ContentOverlay>
        </HeroContainer>
        <SiteWrapper>
          <SectionWrapper id="begining">
            <ContentWrapper>
              <MainFeatures>
                <FeatureCard>
                  <ModernIcon className={classes.icons} />
                  <h2>Modern Design</h2>
                  <p>
                    The Light Pole Defender has the most sleek modern design of
                    all its competitors.
                  </p>
                </FeatureCard>
                <FeatureCard>
                  <EasyInstallIcon className={classes.icons} />
                  <h2>Easy Install</h2>
                  <p>
                    The Install time for the Light Pole Defender is a breeze
                    only taking minutes to install.
                  </p>
                </FeatureCard>
                <FeatureCard>
                  <UniversalIcon className={classes.icons} />
                  <h2>Universal Fit </h2>
                  <p>
                    The Light Pole Defender installs on most 4" and 5" poles,
                    also fitting round and square poles.
                  </p>
                </FeatureCard>
                <FeatureCard>
                  <KeylessIcon className={classes.icons} />
                  <h2>Keyless Entry</h2>
                  <p>
                    No keys needed because Light Pole Defender comes with a 4
                    digit combination lock.
                  </p>
                </FeatureCard>
              </MainFeatures>
            </ContentWrapper>
          </SectionWrapper>
          <SectionWrapper color="#f8fbfd">
            <ContentWrapper>
              <h2>SLEEK.SECURE.MODERN</h2>
              <p>
                The Light Pole Defender is designed to protect your light pole
                without becoming and an eyesore.
              </p>
              <Hr />
              <Box display="flex" justifyContent="center">
                <img
                  alt="locking hand hole cover for light poles"
                  src="product.png"
                />
              </Box>
            </ContentWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <CTAStrip>
              <ContentWrapper>
                <h2>Don't wait to be a victim to protect your light poles.</h2>
                <p> Start exploring options now</p>
                <Box mb="32px" display="flex" justifyContent="center" fullWidth>
                  <Link to="/lpd-product-detail">
                    <CTAButton>Order Now</CTAButton>
                  </Link>
                </Box>
              </ContentWrapper>
            </CTAStrip>
          </SectionWrapper>
          <SectionWrapper leftAlign id="product">
            <ContentWrapper>
              <h2>The Lock</h2>
              <p>
                {" "}
                Here are some reasons you should use
                <Link to="/why-choose-light-pole-defender-to-protect-you-light-poles">
                  {" "}
                  Light Pole Defenders
                </Link>{" "}
                locking hand hole cover.
              </p>
              <Hr leftAlign />
              <LockLayout>
                <FeatureLayout>
                  <LeftAlignedFeature>
                    <h2>Easy Install</h2>
                    <p>
                      The Install time for the Light Pole Defender is a breeze
                      only taking minutes to install.
                    </p>
                  </LeftAlignedFeature>
                  <LeftAlignedFeature>
                    <h2>No keys required</h2>
                    <p>
                      No need to hassle with keys. LPD is designed around an
                      easy to use 4 digit combo lock.
                    </p>
                  </LeftAlignedFeature>
                  <LeftAlignedFeature>
                    <h2>Combo lock included</h2>
                    <p>
                      Unlike the competitors, a 4 digit combination lock is
                      included with each lock purchase.
                    </p>
                  </LeftAlignedFeature>
                  <LeftAlignedFeature>
                    <h2>Powder coat finish</h2>
                    <p>
                      We give each LPD lock a beautiful heavy-duty powder coat
                      to withstand the elements.
                    </p>
                  </LeftAlignedFeature>
                  <LeftAlignedFeature>
                    <h2> Universal fit </h2>
                    <p>
                      The Light Pole Defender installs on most 4" and 5" poles,
                      also fitting round and square poles.
                    </p>
                  </LeftAlignedFeature>
                  <LeftAlignedFeature>
                    <h2>Color Options</h2>
                    <p>
                      The Light Pole Defender comes in white, bronze, and black
                      to insure the best look.
                    </p>
                  </LeftAlignedFeature>
                </FeatureLayout>
                <ImgContainer>
                  <ImageGallery
                    items={IMAGE_GALLERY}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showNav={false}
                    showThumbnails={false}
                    showBullets={true}
                    autoplay={true}
                  />
                </ImgContainer>
              </LockLayout>
            </ContentWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <CTAStrip>
              <ContentWrapper>
                <h2>Request A Free Product Sample</h2>
                <p> See and feel the quality for yourself</p>
                <Box mb="32px" display="flex" justifyContent="center" fullWidth>
                  <Link to="/request-sample?source=homepage-banner">
                    <CTAButton>Request Product</CTAButton>
                  </Link>
                </Box>
              </ContentWrapper>
            </CTAStrip>
          </SectionWrapper>
          <SectionWrapper color="#f8fbfd" id="reviews">
            <ContentWrapper>
              <h2>Reviews</h2>
              <p>
                Don’t take our word. See what our experts says about the lock.
                Checkout out our positive reviews.
              </p>
              <Hr />
              <QuoteContainer>
                <Quote>
                  <img alt="quote-img" src="quote.png" />
                  <p>
                    We get to protect our light poles without sticking a huge
                    metal box on our poles. Thank you Light Pole Defender.
                    <br />
                    <strong>
                      <small>
                        John Pana <br />- Trust Lighting
                      </small>
                    </strong>
                  </p>
                </Quote>
                <Quote>
                  <img alt="quote" src="quote.png" />
                  <p>
                    Not one complaint. Easy to install, looks great, and gives
                    me peace of mind. I highly recommend this product.
                    <br />
                    <strong>
                      <small>
                        Brad Palarmo <br />- Sun Lighting
                      </small>
                    </strong>
                  </p>
                </Quote>
                <Quote>
                  <img alt="quote" src="quote.png" />
                  <p>
                    I couldn't be happier with the quality of this lock. Not to
                    mention its the best looking lock on the market.
                    <br />
                    <strong>
                      <small>
                        Paul Beeler <br />- Lightright
                      </small>
                    </strong>
                  </p>
                </Quote>
              </QuoteContainer>
            </ContentWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <CTAStrip>
              <ContentWrapper>
                <h2>Why its important to protect your light poles</h2>
                <p> Learn why you should protect your light poles</p>
                <Box mb="32px" display="flex" justifyContent="center" fullWidth>
                  <Link to="/the-importance-of-locking-handhole-covers">
                    <CTAButton>Learn More</CTAButton>
                  </Link>
                </Box>
              </ContentWrapper>
            </CTAStrip>
          </SectionWrapper>
          <ContentWrapper>
            <SectionWrapper id="faqs">
              <h2>FAQ</h2>
              <p>
                Got questions? We’ve got answers. If you have some other
                questions, feel free to send us an email to{" "}
                <a href="mailto:info@lightpoledefender.com">
                  info@lightpoledefender.com
                </a>
              </p>
              <Hr />
              <FAQContainer>
                <Faq>
                  <h3>What is the lock made of?</h3>
                  <p>The lock is made out high grade billet aluminum. </p>
                </Faq>
                <Faq>
                  <h3>How is the lock is made?</h3>
                  <p>
                    The lock is made from one piece of aluminum put through a
                    precision CNC process.
                  </p>
                </Faq>
                <Faq>
                  <h3>How long does the lock take to install?</h3>
                  <p>The lock takes minutes to install.</p>
                </Faq>
                <Faq>
                  <h3>What kind of poles do the lights fit on?</h3>
                  <p>The lock fits on round and square lights.</p>
                </Faq>
              </FAQContainer>
            </SectionWrapper>
          </ContentWrapper>
          <SectionWrapper>
            <CTAStrip>
              <ContentWrapper>
                <h2> Prevent copper wire theft</h2>
                <p> Learn how to prevent copper wire theft.</p>
                <Box mb="32px" display="flex" justifyContent="center" fullWidth>
                  <Link to="/how-to-prevent-copper-wire-theft">
                    <CTAButton>Learn More</CTAButton>
                  </Link>
                </Box>
              </ContentWrapper>
            </CTAStrip>
          </SectionWrapper>
        </SiteWrapper>
      </PageLayout>
    </Layout>
  )
}

export default inject("cart")(observer(IndexPage))
